<template>
  <div class="attachments">
    <Header back="返回" title="合作项目" index="首页" titleOne="合同管理" titleTwo="合作项目" beforeTitle="开票" />
    <div class="content">
      <h1>合同信息</h1>
      <el-form
        :model="form"
        :rules="formRule"
        ref="formRef"
        label-width="80px"
        :label-position="labelPosition"
      >
        <el-form-item label="企业名称" prop="company_name">
          <el-autocomplete
            v-model="form.company_name"
            :fetch-suggestions="querySearchAsync"
            @select="handleSelect"
          ></el-autocomplete>
        </el-form-item>
        <el-form-item label="纳税人识别号" prop="taxpayer">
          <el-input v-model="form.taxpayer"></el-input>
        </el-form-item>
        <el-form-item label="发票类型" prop="invoice_type">
          <el-select v-model="form.invoice_type" placeholder="请选择">
            <el-option label="普通发票" :value="1">普通发票</el-option>
            <el-option label="增值税发票" :value="2">增值税发票</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开票基准" prop="billing_basic">
          <el-select v-model="form.billing_basic" placeholder="请选择">
            <el-option label="基于项目" :value="1">基于项目</el-option>
            <el-option label="基于合同" :value="2">基于合同</el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="开户行" prop="bank">
          <el-input v-model="form.bank"></el-input>
        </el-form-item>
        <el-form-item label="开户账号" prop="account">
          <el-input v-model="form.account"></el-input>
        </el-form-item>
        <el-form-item label="地址" prop="address">
          <el-input v-model="form.address"></el-input>
        </el-form-item>
        <el-form-item label="电话" prop="tel">
          <el-input v-model="form.tel"></el-input>
        </el-form-item>
        <el-form-item label="货物或应税劳务、服务名称" prop="service_name">
          <el-input v-model="form.service_name"></el-input>
        </el-form-item>
        <el-form-item label="金额（元）" prop="momey">
          <el-input v-model="form.momey"></el-input>
        </el-form-item>
      </el-form>
      <div class="foot_TJ">
        <el-button type="primary" :loading="btnLoading" @click="onSubmit">确定</el-button>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data () {
    return {
      btnLoading: false,//提交按钮动画
      labelPosition: "top",
      form: {
        company_name: "",
        taxpayer: "",
        invoice_type: "",
        billing_basic: "",
        bank: "",
        account: "",
        address: "",
        tel: "",
        service_name: "",
        momey: "",
        id: "",
        contract_project_id: "",
      },
      formRule: {
        company_name: [
          {
            required: true,
            message: "请输入企业名称",
            trigger: "blur",
          },
        ],
        taxpayer: [
          {
            required: true,
            message: "请输入纳税人识别号",
            trigger: "blur",
          },
        ],
        invoice_type: [
          {
            required: true,
            message: "请选择发票类型",
            trigger: "change",
          },
        ],
        billing_basic: [
          {
            required: true,
            message: "请选择开票基准",
            trigger: "change",
          },
        ],
        bank: [
          {
            required: true,
            message: "请输入开户行",
            trigger: "blur",
          },
        ],
        account: [
          {
            required: true,
            message: "请输入开户账号",
            trigger: "blur",
          },
        ],
        address: [
          {
            required: true,
            message: "请输入地址",
            trigger: "blur",
          },
        ],
        tel: [
          {
            required: true,
            message: "请输入电话",
            trigger: "blur",
          },
        ],
        service_name: [
          {
            required: true,
            message: "请输入货物或应税劳务、服务名称",
            trigger: "blur",
          },
        ],
        momey: [
          {
            required: true,
            message: "请输入金额",
            trigger: "blur",
          },
        ],
      },
    };
  },
  computed: {
    meber_id () {
      return this.$route.query.id;
    },
  },
  mounted () {
    if (this.meber_id) {
      this.axios
        .get("/api/contract/view_invoice", {
          params: {
            contract_project_id: this.meber_id,
          },
        })
        .then((res) => {
          console.log(res);
          this.form = res.data ? res.data : {};
          this.id = res.data.id ? res.data.id : "";
          console.log(this.form.id);
        });
    }
  },
  methods: {
    querySearchAsync (queryString, cb) {
      clearTimeout(this.timeout);
      var results = [];
      if (queryString == "") {
        cb(results);
      } else {
        this.axios
          .get("/api/company/select_company", {
            params: {
              keywords: queryString,
            },
          })
          .then((res) => {
            if (res.message == "查询成功") {
              for (let i = 0; i < res.data.length; i++) {
                const element = res.data[i];
                console.log(element);
                results.push({
                  value: element.company_name,
                  id: element.id,
                });
                console.log(results);
              }
              cb(results);
            } else {
              results = [];
              cb(results);
            }
          });
      }
    },
    //点击出现搜索后点击的每一项
    handleSelect (item) {
      console.log(item);
      this.form.com_id = item.id;
      this.form.company_name = item.value;
    },
    onSubmit () {
      this.$refs.formRef.validate((valid) => {
        this.form.contract_project_id = this.meber_id;
        this.form.id = this.id;
        if (valid) {
          this.btnLoading = true
          this.axios
            .post("/api/contract/store_invoice", this.form)
            .then((res) => {
              this.$message.success("开票成功");
              this.$router.push("/contract/project");
            })
            .catch((res) => {
              console.log("err:", res);
              this.btnLoading = false
            });
          this.dialogFormVisible = false;
        } else {
          console.log("输入错误");
          this.btnLoading = false
        }
      });
    },
  },
};
</script>

<style scoped="scoped">
::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.el-select {
  width: 100%;
}

.upload-demo {
  display: inline-block;
}

.ChooseFile {
  width: 224px;
  height: 40px;
  background: #edeef4 !important;
  color: #909399 !important;
  border: none;
}

.UploadFile {
  width: 88px;
  height: 40px;
  background: #0f38ff !important;
  color: #fff !important;
  border: none;
  margin-left: 20px;
}

.el-upload-list {
  display: inline-block;
}

.el-upload-list__item-name {
  margin-top: 20px;
}

::v-deep.el-form-item {
  width: 23%;
  display: inline-block;
  margin-right: 2%;
}

.ImportTop span {
  color: #a6abc7;
}
</style>
